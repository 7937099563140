import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n2 from "../../images/news2.jpg";
import NFooter from "../../components/nFooter";

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="iTelaSoft Celebrating Global Success"
        description="iTelaSoft, one of the most innovative companies in the IT
                  space celebrated their “Champion Teams” at the annual event
                  held at Jetwing Colombo 7 on the 25 th January."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <img alt="iTelaSoft celebrating success" src={n2} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">EVENTS | FEBRUARY 5, 2019</div>
              <h1>iTelaSoft Celebrating Global Success</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  iTelaSoft, one of the most innovative companies in the IT
                  space celebrated their “Champion Teams” at the annual event
                  held at Jetwing Colombo 7 on the 25 th January. The Company
                  which started as a five-person operation in 2009 has now grown
                  to a 75-plus operation, serving a number of clients around the
                  globe.
                </p>
                <p>
                  Based out of Sydney Australia with its development hub located
                  in Colombo Sri Lanka, iTelaSoft has shown steady growth during
                  the years, taking on innovative technology solutions for its
                  client around the globe.
                </p>
                <p>
                  Addressing the iTelaSoft team and guests, Mr. Indaka Raigama,
                  CEO Global Operations, said “The last year has been a very
                  successful one for the company, with the growth of accounts &
                  the ability of the teams to deliver exceptional customer
                  satisfaction through the efforts of our dedicated team. At our
                  core, we are an engineering company passionate in innovating
                  solutions that make the world better, and our clients
                  successful. In that journey, not only our tech skills but also
                  our values and cultural compatibility plays a large role; and
                  that’s why our clients love our team!” He mentioned that the
                  company is moving in a growth trajectory, serving & expanding
                  in Australia Europe, Canada & USA. In order to facilitate this
                  growth, the company continues to invest in people & assets
                  looking at its future.
                </p>
                <p>
                  Speaking at the event, Guest Speaker Mr. Sachindra
                  Samararathne, Program Manager ICTA said “The IT industry in
                  Sri Lanka is geared to take up the number one export earning
                  position for Sri Lanka in the near future. And it’s the
                  dedication & skills of our people that will be crucial to
                  achieve this feat.” Commending the growth and vision of
                  iTelaSoft, Mr. Samararathne emphasized the need for quality
                  and trustworthy solution partners to make Sri Lanka visible in
                  the technological world map.
                </p>
                <p>
                  The company which has successfully expanded it’s foot print
                  globally is known for solutions in software product
                  engineering, fintech solutions, IoT and device solutions as
                  well as data science and machine learning.
                </p>
                <p>
                  iTelaSoft strives to help organizations take a leap in
                  technology, by making things simple.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NFooter
        prv1="/noteworthy/new-it-platform-initiative-from-itelasoft"
        prv2="/noteworthy/new-it-platform-initiative-from-itelasoft"
        next1="/noteworthy/empowering-the-next-generation"
        next2="/noteworthy/empowering-the-next-generation"
        titlel="iTelaSoft Leaps into Narrowband IoT"
        titler="Empowering the next generation"
      />
    </Layout>
  );
};

export default News;
