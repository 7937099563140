import React from "react";
import { Link } from "gatsby";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaTumblr } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";




class NFooter extends React.Component {
  render() {
    return (
      <div className="nfooter-item">
        <div className="container">
          <div className="nfooter-content">
            <div className="d-flex social-ic">
              <div className="ic"><a href="https://www.facebook.com/" rel="noopener" aria-label="facebook" target="_blank"><FaFacebookF /></a></div>
              <div className="ic"><a href="https://twitter.com" rel="noopener" aria-label="twitter" target="_blank"><FaTwitter /></a></div>
              <div className="ic"><a href="https://www.pinterest.com" rel="noopener" aria-label="pinterest" target="_blank"><FaPinterestP /></a></div>
              <div className="ic"><a href="https://www.tumblr.com/" rel="noopener" aria-label="tumblr" target="_blank"><FaTumblr /></a></div>
              <div className="ic"><a href="mailto:info@itelasoft.com/" rel="noopener" aria-label="mailto" target="_blank"><FaEnvelope /></a></div>
            </div>
          </div>
          <hr />
          <div className="nfooter-links">
            <div className="left">
              <a className="link1" href={this.props.prv1}>
                PREVIOUS
          </a>
          <a className="link2" href={this.props.prv2}>
          <p>{this.props.titlel}​​​​​​​​</p>
          </a>
            </div>
            <div className="right">
              <a className="link1" href={this.props.next1}>
                Next
              </a>
              <a className="link2" href={this.props.next2}>
              <p>{this.props.titler}​​​​​​​​</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NFooter;
